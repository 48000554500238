import { authHeader, getUrl } from 'utils/graphqlConfig';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(getUrl() as string, {
    method: "POST",
    ...(authHeader()),
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type JwtToken = {
  __typename?: 'JwtToken';
  access_token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  copyProduct?: Maybe<Scalars['Boolean']>;
  copyResource?: Maybe<Scalars['Boolean']>;
  createProduct: Product;
  createProductCategory: ProductCategory;
  createResource: Resource;
  createResourceCategory: ResourceCategory;
  deleteProduct?: Maybe<Scalars['Boolean']>;
  deleteProductCategory?: Maybe<Scalars['Boolean']>;
  deleteResource?: Maybe<Scalars['Boolean']>;
  deleteResourceCategory?: Maybe<Scalars['Boolean']>;
  login: JwtToken;
  register: User;
  renameProductCategory: ProductCategory;
  renameResourceCategory: ResourceCategory;
  setProfitMargin: Organization;
  updateProduct?: Maybe<Scalars['Boolean']>;
  updateResource?: Maybe<Scalars['Boolean']>;
};


export type MutationCopyProductArgs = {
  copyName: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationCopyResourceArgs = {
  copyName: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationCreateProductArgs = {
  categoryId: Scalars['Int'];
  name: Scalars['String'];
  profitMargin: Scalars['Float'];
  selectedResources: Array<SelectedResources>;
  sellPrice: Scalars['Float'];
  utilities: Scalars['Float'];
};


export type MutationCreateProductCategoryArgs = {
  name: Scalars['String'];
};


export type MutationCreateResourceArgs = {
  categoryId: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  unit: Scalars['String'];
};


export type MutationCreateResourceCategoryArgs = {
  name: Scalars['String'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteResourceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteResourceCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRenameProductCategoryArgs = {
  id: Scalars['Int'];
  newName: Scalars['String'];
};


export type MutationRenameResourceCategoryArgs = {
  id: Scalars['Int'];
  newName: Scalars['String'];
};


export type MutationSetProfitMarginArgs = {
  profitMargin: Scalars['Float'];
};


export type MutationUpdateProductArgs = {
  categoryId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  profitMargin: Scalars['Float'];
  selectedResources: Array<SelectedResources>;
  sellPrice: Scalars['Float'];
  utilities: Scalars['Float'];
};


export type MutationUpdateResourceArgs = {
  categoryId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  unit: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  defaultProfitMargin: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  productCategories: Array<ProductCategory>;
  products: Array<Product>;
  resourceCategories: Array<ResourceCategory>;
  resources: Array<Resource>;
  users: Array<User>;
};

export type PaginatedProductCategories = {
  __typename?: 'PaginatedProductCategories';
  hasNextPage: Scalars['Boolean'];
  nodes: Array<ProductCategory>;
};

export type PaginatedProducts = {
  __typename?: 'PaginatedProducts';
  hasNextPage: Scalars['Boolean'];
  nodes: Array<Product>;
};

export type PaginatedResourceCategories = {
  __typename?: 'PaginatedResourceCategories';
  hasNextPage: Scalars['Boolean'];
  nodes: Array<ResourceCategory>;
};

export type PaginatedResources = {
  __typename?: 'PaginatedResources';
  hasNextPage: Scalars['Boolean'];
  nodes: Array<Resource>;
};

export type Product = {
  __typename?: 'Product';
  category: ProductCategory;
  id: Scalars['Int'];
  name: Scalars['String'];
  organization: Organization;
  profitMargin: Scalars['Float'];
  resourceItems: Array<ResourceItem>;
  sellPrice: Scalars['Float'];
  utilities: Scalars['Float'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  organization: Organization;
  products: PaginatedProducts;
};


export type ProductCategoryProductsArgs = {
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  organization: Organization;
  product: Product;
  productCategories: PaginatedProductCategories;
  productCategory: ProductCategory;
  resource: Resource;
  resourceCategories: PaginatedResourceCategories;
  resourceCategory: ResourceCategory;
  whoAmI: User;
};


export type QueryOrganizationArgs = {
  id: Scalars['Int'];
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryProductCategoriesArgs = {
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
};


export type QueryProductCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryResourceArgs = {
  id: Scalars['Int'];
};


export type QueryResourceCategoriesArgs = {
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
};


export type QueryResourceCategoryArgs = {
  id: Scalars['Int'];
};

export type Resource = {
  __typename?: 'Resource';
  category: ResourceCategory;
  id: Scalars['Int'];
  name: Scalars['String'];
  organization: Organization;
  price: Scalars['Float'];
  unit: Scalars['String'];
};

export type ResourceCategory = {
  __typename?: 'ResourceCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  organization: Organization;
  resources: PaginatedResources;
};


export type ResourceCategoryResourcesArgs = {
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
};

export type ResourceItem = {
  __typename?: 'ResourceItem';
  amount: Scalars['Float'];
  id: Scalars['Int'];
  product: Product;
  resource: Resource;
};

export type SelectedResources = {
  amount: Scalars['Float'];
  id: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  organization: Organization;
};

export type CopyProductMutationVariables = Exact<{
  copyName: Scalars['String'];
  id: Scalars['Int'];
}>;


export type CopyProductMutation = { __typename?: 'Mutation', copyProduct?: boolean | null | undefined };

export type CopyResourceMutationVariables = Exact<{
  copyName: Scalars['String'];
  id: Scalars['Int'];
}>;


export type CopyResourceMutation = { __typename?: 'Mutation', copyResource?: boolean | null | undefined };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct?: boolean | null | undefined };

export type DeleteProductCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductCategoryMutation = { __typename?: 'Mutation', deleteProductCategory?: boolean | null | undefined };

export type DeleteResourceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteResourceMutation = { __typename?: 'Mutation', deleteResource?: boolean | null | undefined };

export type DeleteResourceCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteResourceCategoryMutation = { __typename?: 'Mutation', deleteResourceCategory?: boolean | null | undefined };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'JwtToken', access_token: string } };

export type CreateProductCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategory: { __typename?: 'ProductCategory', name: string, id: number } };

export type CreateProductMutationVariables = Exact<{
  name: Scalars['String'];
  sellPrice: Scalars['Float'];
  categoryId: Scalars['Int'];
  selectedResources: Array<SelectedResources> | SelectedResources;
  profitMargin: Scalars['Float'];
  utilities: Scalars['Float'];
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'Product', id: number, name: string } };

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  categoryId: Scalars['Int'];
  sellPrice: Scalars['Float'];
  selectedResources: Array<SelectedResources> | SelectedResources;
  profitMargin: Scalars['Float'];
  utilities: Scalars['Float'];
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: boolean | null | undefined };

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  organizationName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'User', firstName: string, lastName: string } };

export type RenameProductCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  newName: Scalars['String'];
}>;


export type RenameProductCategoryMutation = { __typename?: 'Mutation', renameProductCategory: { __typename?: 'ProductCategory', id: number, name: string } };

export type RenameResourceCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  newName: Scalars['String'];
}>;


export type RenameResourceCategoryMutation = { __typename?: 'Mutation', renameResourceCategory: { __typename?: 'ResourceCategory', id: number, name: string } };

export type ResourceCategoryCreateMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type ResourceCategoryCreateMutation = { __typename?: 'Mutation', createResourceCategory: { __typename?: 'ResourceCategory', id: number, name: string } };

export type CreateResourceMutationVariables = Exact<{
  name: Scalars['String'];
  price: Scalars['Float'];
  unit: Scalars['String'];
  categoryId: Scalars['Int'];
}>;


export type CreateResourceMutation = { __typename?: 'Mutation', createResource: { __typename?: 'Resource', id: number, name: string } };

export type SetProfitMarginMutationVariables = Exact<{
  profitMargin: Scalars['Float'];
}>;


export type SetProfitMarginMutation = { __typename?: 'Mutation', setProfitMargin: { __typename?: 'Organization', name: string, defaultProfitMargin: number } };

export type UpdateResourceMutationVariables = Exact<{
  id: Scalars['Int'];
  categoryId: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  unit: Scalars['String'];
}>;


export type UpdateResourceMutation = { __typename?: 'Mutation', updateResource?: boolean | null | undefined };

export type DefaultProfitMarginQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultProfitMarginQuery = { __typename?: 'Query', whoAmI: { __typename?: 'User', organization: { __typename?: 'Organization', defaultProfitMargin: number } } };

export type GetProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', name: string, id: number, sellPrice: number, utilities: number, profitMargin: number, resourceItems: Array<{ __typename?: 'ResourceItem', amount: number, resource: { __typename?: 'Resource', id: number, name: string, price: number, unit: string } }>, category: { __typename?: 'ProductCategory', id: number, name: string } } };

export type GetProductCategoriesQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetProductCategoriesQuery = { __typename?: 'Query', productCategories: { __typename?: 'PaginatedProductCategories', hasNextPage: boolean, nodes: Array<{ __typename?: 'ProductCategory', id: number, name: string, products: { __typename?: 'PaginatedProducts', hasNextPage: boolean, nodes: Array<{ __typename?: 'Product', id: number, name: string, sellPrice: number }> } }> } };

export type GetProductCategoryNamesQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetProductCategoryNamesQuery = { __typename?: 'Query', productCategories: { __typename?: 'PaginatedProductCategories', hasNextPage: boolean, nodes: Array<{ __typename?: 'ProductCategory', id: number, name: string }> } };

export type GetResourceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetResourceQuery = { __typename?: 'Query', resource: { __typename?: 'Resource', id: number, name: string, price: number, unit: string, category: { __typename?: 'ResourceCategory', id: number, name: string } } };

export type ResourceCategoriesQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type ResourceCategoriesQuery = { __typename?: 'Query', resourceCategories: { __typename?: 'PaginatedResourceCategories', hasNextPage: boolean, nodes: Array<{ __typename?: 'ResourceCategory', id: number, name: string, resources: { __typename?: 'PaginatedResources', hasNextPage: boolean, nodes: Array<{ __typename?: 'Resource', id: number, name: string, unit: string, price: number }> } }> } };

export type GetResourceCategoryNamesQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetResourceCategoryNamesQuery = { __typename?: 'Query', resourceCategories: { __typename?: 'PaginatedResourceCategories', hasNextPage: boolean, nodes: Array<{ __typename?: 'ResourceCategory', id: number, name: string }> } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'User', firstName: string, lastName: string } };


export const CopyProductDocument = `
    mutation CopyProduct($copyName: String!, $id: Int!) {
  copyProduct(copyName: $copyName, id: $id)
}
    `;
export const useCopyProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CopyProductMutation, TError, CopyProductMutationVariables, TContext>) =>
    useMutation<CopyProductMutation, TError, CopyProductMutationVariables, TContext>(
      'CopyProduct',
      (variables?: CopyProductMutationVariables) => fetcher<CopyProductMutation, CopyProductMutationVariables>(CopyProductDocument, variables)(),
      options
    );
export const CopyResourceDocument = `
    mutation CopyResource($copyName: String!, $id: Int!) {
  copyResource(copyName: $copyName, id: $id)
}
    `;
export const useCopyResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CopyResourceMutation, TError, CopyResourceMutationVariables, TContext>) =>
    useMutation<CopyResourceMutation, TError, CopyResourceMutationVariables, TContext>(
      'CopyResource',
      (variables?: CopyResourceMutationVariables) => fetcher<CopyResourceMutation, CopyResourceMutationVariables>(CopyResourceDocument, variables)(),
      options
    );
export const DeleteProductDocument = `
    mutation DeleteProduct($id: Int!) {
  deleteProduct(id: $id)
}
    `;
export const useDeleteProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteProductMutation, TError, DeleteProductMutationVariables, TContext>) =>
    useMutation<DeleteProductMutation, TError, DeleteProductMutationVariables, TContext>(
      'DeleteProduct',
      (variables?: DeleteProductMutationVariables) => fetcher<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, variables)(),
      options
    );
export const DeleteProductCategoryDocument = `
    mutation DeleteProductCategory($id: Int!) {
  deleteProductCategory(id: $id)
}
    `;
export const useDeleteProductCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteProductCategoryMutation, TError, DeleteProductCategoryMutationVariables, TContext>) =>
    useMutation<DeleteProductCategoryMutation, TError, DeleteProductCategoryMutationVariables, TContext>(
      'DeleteProductCategory',
      (variables?: DeleteProductCategoryMutationVariables) => fetcher<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>(DeleteProductCategoryDocument, variables)(),
      options
    );
export const DeleteResourceDocument = `
    mutation DeleteResource($id: Int!) {
  deleteResource(id: $id)
}
    `;
export const useDeleteResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteResourceMutation, TError, DeleteResourceMutationVariables, TContext>) =>
    useMutation<DeleteResourceMutation, TError, DeleteResourceMutationVariables, TContext>(
      'DeleteResource',
      (variables?: DeleteResourceMutationVariables) => fetcher<DeleteResourceMutation, DeleteResourceMutationVariables>(DeleteResourceDocument, variables)(),
      options
    );
export const DeleteResourceCategoryDocument = `
    mutation DeleteResourceCategory($id: Int!) {
  deleteResourceCategory(id: $id)
}
    `;
export const useDeleteResourceCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteResourceCategoryMutation, TError, DeleteResourceCategoryMutationVariables, TContext>) =>
    useMutation<DeleteResourceCategoryMutation, TError, DeleteResourceCategoryMutationVariables, TContext>(
      'DeleteResourceCategory',
      (variables?: DeleteResourceCategoryMutationVariables) => fetcher<DeleteResourceCategoryMutation, DeleteResourceCategoryMutationVariables>(DeleteResourceCategoryDocument, variables)(),
      options
    );
export const LoginDocument = `
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    access_token
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      'Login',
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(LoginDocument, variables)(),
      options
    );
export const CreateProductCategoryDocument = `
    mutation CreateProductCategory($name: String!) {
  createProductCategory(name: $name) {
    name
    id
  }
}
    `;
export const useCreateProductCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateProductCategoryMutation, TError, CreateProductCategoryMutationVariables, TContext>) =>
    useMutation<CreateProductCategoryMutation, TError, CreateProductCategoryMutationVariables, TContext>(
      'CreateProductCategory',
      (variables?: CreateProductCategoryMutationVariables) => fetcher<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, variables)(),
      options
    );
export const CreateProductDocument = `
    mutation CreateProduct($name: String!, $sellPrice: Float!, $categoryId: Int!, $selectedResources: [SelectedResources!]!, $profitMargin: Float!, $utilities: Float!) {
  createProduct(
    name: $name
    sellPrice: $sellPrice
    categoryId: $categoryId
    selectedResources: $selectedResources
    profitMargin: $profitMargin
    utilities: $utilities
  ) {
    id
    name
  }
}
    `;
export const useCreateProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateProductMutation, TError, CreateProductMutationVariables, TContext>) =>
    useMutation<CreateProductMutation, TError, CreateProductMutationVariables, TContext>(
      'CreateProduct',
      (variables?: CreateProductMutationVariables) => fetcher<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, variables)(),
      options
    );
export const UpdateProductDocument = `
    mutation UpdateProduct($id: Int!, $name: String!, $categoryId: Int!, $sellPrice: Float!, $selectedResources: [SelectedResources!]!, $profitMargin: Float!, $utilities: Float!) {
  updateProduct(
    id: $id
    name: $name
    categoryId: $categoryId
    sellPrice: $sellPrice
    selectedResources: $selectedResources
    profitMargin: $profitMargin
    utilities: $utilities
  )
}
    `;
export const useUpdateProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>) =>
    useMutation<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>(
      'UpdateProduct',
      (variables?: UpdateProductMutationVariables) => fetcher<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, variables)(),
      options
    );
export const RegisterDocument = `
    mutation Register($email: String!, $organizationName: String!, $firstName: String!, $lastName: String!, $password: String!) {
  register(
    email: $email
    firstName: $firstName
    lastName: $lastName
    password: $password
    organizationName: $organizationName
  ) {
    firstName
    lastName
  }
}
    `;
export const useRegisterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RegisterMutation, TError, RegisterMutationVariables, TContext>) =>
    useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
      'Register',
      (variables?: RegisterMutationVariables) => fetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables)(),
      options
    );
export const RenameProductCategoryDocument = `
    mutation RenameProductCategory($id: Int!, $newName: String!) {
  renameProductCategory(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export const useRenameProductCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RenameProductCategoryMutation, TError, RenameProductCategoryMutationVariables, TContext>) =>
    useMutation<RenameProductCategoryMutation, TError, RenameProductCategoryMutationVariables, TContext>(
      'RenameProductCategory',
      (variables?: RenameProductCategoryMutationVariables) => fetcher<RenameProductCategoryMutation, RenameProductCategoryMutationVariables>(RenameProductCategoryDocument, variables)(),
      options
    );
export const RenameResourceCategoryDocument = `
    mutation RenameResourceCategory($id: Int!, $newName: String!) {
  renameResourceCategory(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export const useRenameResourceCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RenameResourceCategoryMutation, TError, RenameResourceCategoryMutationVariables, TContext>) =>
    useMutation<RenameResourceCategoryMutation, TError, RenameResourceCategoryMutationVariables, TContext>(
      'RenameResourceCategory',
      (variables?: RenameResourceCategoryMutationVariables) => fetcher<RenameResourceCategoryMutation, RenameResourceCategoryMutationVariables>(RenameResourceCategoryDocument, variables)(),
      options
    );
export const ResourceCategoryCreateDocument = `
    mutation ResourceCategoryCreate($name: String!) {
  createResourceCategory(name: $name) {
    id
    name
  }
}
    `;
export const useResourceCategoryCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResourceCategoryCreateMutation, TError, ResourceCategoryCreateMutationVariables, TContext>) =>
    useMutation<ResourceCategoryCreateMutation, TError, ResourceCategoryCreateMutationVariables, TContext>(
      'ResourceCategoryCreate',
      (variables?: ResourceCategoryCreateMutationVariables) => fetcher<ResourceCategoryCreateMutation, ResourceCategoryCreateMutationVariables>(ResourceCategoryCreateDocument, variables)(),
      options
    );
export const CreateResourceDocument = `
    mutation CreateResource($name: String!, $price: Float!, $unit: String!, $categoryId: Int!) {
  createResource(name: $name, price: $price, unit: $unit, categoryId: $categoryId) {
    id
    name
  }
}
    `;
export const useCreateResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateResourceMutation, TError, CreateResourceMutationVariables, TContext>) =>
    useMutation<CreateResourceMutation, TError, CreateResourceMutationVariables, TContext>(
      'CreateResource',
      (variables?: CreateResourceMutationVariables) => fetcher<CreateResourceMutation, CreateResourceMutationVariables>(CreateResourceDocument, variables)(),
      options
    );
export const SetProfitMarginDocument = `
    mutation SetProfitMargin($profitMargin: Float!) {
  setProfitMargin(profitMargin: $profitMargin) {
    name
    defaultProfitMargin
  }
}
    `;
export const useSetProfitMarginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetProfitMarginMutation, TError, SetProfitMarginMutationVariables, TContext>) =>
    useMutation<SetProfitMarginMutation, TError, SetProfitMarginMutationVariables, TContext>(
      'SetProfitMargin',
      (variables?: SetProfitMarginMutationVariables) => fetcher<SetProfitMarginMutation, SetProfitMarginMutationVariables>(SetProfitMarginDocument, variables)(),
      options
    );
export const UpdateResourceDocument = `
    mutation UpdateResource($id: Int!, $categoryId: Int!, $name: String!, $price: Float!, $unit: String!) {
  updateResource(
    id: $id
    categoryId: $categoryId
    name: $name
    unit: $unit
    price: $price
  )
}
    `;
export const useUpdateResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateResourceMutation, TError, UpdateResourceMutationVariables, TContext>) =>
    useMutation<UpdateResourceMutation, TError, UpdateResourceMutationVariables, TContext>(
      'UpdateResource',
      (variables?: UpdateResourceMutationVariables) => fetcher<UpdateResourceMutation, UpdateResourceMutationVariables>(UpdateResourceDocument, variables)(),
      options
    );
export const DefaultProfitMarginDocument = `
    query DefaultProfitMargin {
  whoAmI {
    organization {
      defaultProfitMargin
    }
  }
}
    `;
export const useDefaultProfitMarginQuery = <
      TData = DefaultProfitMarginQuery,
      TError = unknown
    >(
      variables?: DefaultProfitMarginQueryVariables,
      options?: UseQueryOptions<DefaultProfitMarginQuery, TError, TData>
    ) =>
    useQuery<DefaultProfitMarginQuery, TError, TData>(
      variables === undefined ? ['DefaultProfitMargin'] : ['DefaultProfitMargin', variables],
      fetcher<DefaultProfitMarginQuery, DefaultProfitMarginQueryVariables>(DefaultProfitMarginDocument, variables),
      options
    );
export const GetProductDocument = `
    query GetProduct($id: Int!) {
  product(id: $id) {
    name
    id
    sellPrice
    utilities
    profitMargin
    resourceItems {
      amount
      resource {
        id
        name
        price
        unit
      }
    }
    category {
      id
      name
    }
  }
}
    `;
export const useGetProductQuery = <
      TData = GetProductQuery,
      TError = unknown
    >(
      variables: GetProductQueryVariables,
      options?: UseQueryOptions<GetProductQuery, TError, TData>
    ) =>
    useQuery<GetProductQuery, TError, TData>(
      ['GetProduct', variables],
      fetcher<GetProductQuery, GetProductQueryVariables>(GetProductDocument, variables),
      options
    );
export const GetProductCategoriesDocument = `
    query GetProductCategories($pageSize: Int!, $skip: Int!) {
  productCategories(pageSize: $pageSize, skip: $skip) {
    hasNextPage
    nodes {
      id
      name
      products(pageSize: 9999, skip: 0) {
        hasNextPage
        nodes {
          id
          name
          sellPrice
        }
      }
    }
  }
}
    `;
export const useGetProductCategoriesQuery = <
      TData = GetProductCategoriesQuery,
      TError = unknown
    >(
      variables: GetProductCategoriesQueryVariables,
      options?: UseQueryOptions<GetProductCategoriesQuery, TError, TData>
    ) =>
    useQuery<GetProductCategoriesQuery, TError, TData>(
      ['GetProductCategories', variables],
      fetcher<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, variables),
      options
    );
export const GetProductCategoryNamesDocument = `
    query GetProductCategoryNames($pageSize: Int!, $skip: Int!) {
  productCategories(pageSize: $pageSize, skip: $skip) {
    hasNextPage
    nodes {
      id
      name
    }
  }
}
    `;
export const useGetProductCategoryNamesQuery = <
      TData = GetProductCategoryNamesQuery,
      TError = unknown
    >(
      variables: GetProductCategoryNamesQueryVariables,
      options?: UseQueryOptions<GetProductCategoryNamesQuery, TError, TData>
    ) =>
    useQuery<GetProductCategoryNamesQuery, TError, TData>(
      ['GetProductCategoryNames', variables],
      fetcher<GetProductCategoryNamesQuery, GetProductCategoryNamesQueryVariables>(GetProductCategoryNamesDocument, variables),
      options
    );
export const GetResourceDocument = `
    query GetResource($id: Int!) {
  resource(id: $id) {
    id
    name
    price
    unit
    category {
      id
      name
    }
  }
}
    `;
export const useGetResourceQuery = <
      TData = GetResourceQuery,
      TError = unknown
    >(
      variables: GetResourceQueryVariables,
      options?: UseQueryOptions<GetResourceQuery, TError, TData>
    ) =>
    useQuery<GetResourceQuery, TError, TData>(
      ['GetResource', variables],
      fetcher<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, variables),
      options
    );
export const ResourceCategoriesDocument = `
    query ResourceCategories($pageSize: Int!, $skip: Int!) {
  resourceCategories(pageSize: $pageSize, skip: $skip) {
    hasNextPage
    nodes {
      id
      name
      resources(pageSize: 9999, skip: 0) {
        hasNextPage
        nodes {
          id
          name
          unit
          price
        }
      }
    }
  }
}
    `;
export const useResourceCategoriesQuery = <
      TData = ResourceCategoriesQuery,
      TError = unknown
    >(
      variables: ResourceCategoriesQueryVariables,
      options?: UseQueryOptions<ResourceCategoriesQuery, TError, TData>
    ) =>
    useQuery<ResourceCategoriesQuery, TError, TData>(
      ['ResourceCategories', variables],
      fetcher<ResourceCategoriesQuery, ResourceCategoriesQueryVariables>(ResourceCategoriesDocument, variables),
      options
    );
export const GetResourceCategoryNamesDocument = `
    query GetResourceCategoryNames($pageSize: Int!, $skip: Int!) {
  resourceCategories(pageSize: $pageSize, skip: $skip) {
    hasNextPage
    nodes {
      id
      name
    }
  }
}
    `;
export const useGetResourceCategoryNamesQuery = <
      TData = GetResourceCategoryNamesQuery,
      TError = unknown
    >(
      variables: GetResourceCategoryNamesQueryVariables,
      options?: UseQueryOptions<GetResourceCategoryNamesQuery, TError, TData>
    ) =>
    useQuery<GetResourceCategoryNamesQuery, TError, TData>(
      ['GetResourceCategoryNames', variables],
      fetcher<GetResourceCategoryNamesQuery, GetResourceCategoryNamesQueryVariables>(GetResourceCategoryNamesDocument, variables),
      options
    );
export const WhoAmIDocument = `
    query WhoAmI {
  whoAmI {
    firstName
    lastName
  }
}
    `;
export const useWhoAmIQuery = <
      TData = WhoAmIQuery,
      TError = unknown
    >(
      variables?: WhoAmIQueryVariables,
      options?: UseQueryOptions<WhoAmIQuery, TError, TData>
    ) =>
    useQuery<WhoAmIQuery, TError, TData>(
      variables === undefined ? ['WhoAmI'] : ['WhoAmI', variables],
      fetcher<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, variables),
      options
    );