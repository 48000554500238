import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import AppLayout from "components/AppLayout";
import DeleteModal from "components/DeleteModal";
import Input from "components/Input";
import Modal from "components/Modal";
import ResourceEditor from "components/ResourceEditor";
import { ResourceCreate } from "components/ResourceEditor/types";
import {
  useCopyResourceMutation,
  useDeleteResourceMutation,
  useGetResourceQuery,
  useUpdateResourceMutation,
} from "generated/graphql";
import useModal from "hooks/useModal";
import { useGlobalNotification } from "hooks/useNotification";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { classNames } from "utils/classnames";
import routes from "utils/routes";

const imgUrl = "/EmptyProduct.png";

export default function ResourceEdit() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const _id = parseInt(id as string);
  const [, setNotification] = useGlobalNotification();
  const queryClient = useQueryClient();
  const { data, status, error } = useGetResourceQuery({ id: _id });

  useEffect(() => {
    if (error instanceof Error) {
      setNotification({
        title: `Couldn't load resource with ID: ${id}`,
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, id, setNotification]);

  const { mutateAsync: update } = useUpdateResourceMutation();
  const { mutateAsync: copy } = useCopyResourceMutation();
  const [isCopyOpen, openCopy, closeCopy] = useModal();
  const { mutateAsync: deleteResource } = useDeleteResourceMutation();
  const [isOpenDelete, openDelete, closeDelete] = useModal();

  const resource = data
    ? {
        ...data.resource,
        imageUrl: imgUrl,
        categoryId: data.resource.category.id,
      }
    : null;

  const onSave = async (_resource: ResourceCreate) => {
    try {
      if (resource === null) {
        console.error("resource is null");
        return;
      }
      const { categoryId: _categoryId, ...rest } = _resource;
      const categoryId = parseInt(_categoryId);
      const vars = {
        id: resource.id,
        categoryId,
        ...rest,
      };
      await update(vars);
      queryClient.invalidateQueries("ResourceCategories");
      navigate(routes.resources);
      setNotification({
        title: "Resource updated",
        description: "Resource has been successfully updated on our servers",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: `Couldn't update resource with ID: ${id}`,
        description: error.message,
        iconType: "error",
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteResource({
        id: _id,
      });
      queryClient.invalidateQueries("ResourceCategories");
      navigate(routes.resources);
      setNotification({
        title: "Resource deleted",
        description: "Resource has been successfully deleted from our servers",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: `Couldn't delete resource with ID: ${id}`,
        description: error.message,
        iconType: "error",
      });
    }
  };

  const resourceActions = [
    {
      name: t("resource.copy.button"),
      onClick: openCopy,
    },
  ];

  return (
    <AppLayout title={t("resource.edit")} currentNav="nav.resources">
      <Menu as="div" className="inline-block pb-4 relative text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            {t("resource.actions")}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {resourceActions.map((action, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      onClick={action.onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 w-full text-sm"
                      )}
                    >
                      {action.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={openDelete}
                    className={classNames(
                      active ? "bg-red-100 text-red-900" : "text-red-700",
                      "block px-4 py-2 w-full text-sm"
                    )}
                  >
                    {t("resource.delete")}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {resource && (
        <ResourceEditor
          onSave={onSave}
          onCancel={() =>
            navigate((location.state as string) ?? routes.resources)
          }
          resource={resource}
        />
      )}
      <DeleteModal
        title="Delete resource"
        description="Are you sure you want to delete this resource?"
        enterText="Delete"
        isOpen={isOpenDelete}
        onClose={closeDelete}
        onEnter={onDelete}
      />
      <Modal
        title={t("resource.copy.title")}
        open={isCopyOpen}
        onClose={closeCopy}
        enterText={t("resource.copy.button")}
        initialValues={{
          copyName: `${data ? data.resource.name : "Resource"} copy`,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await copy({
              copyName: values.copyName,
              id: _id,
            });
            queryClient.invalidateQueries("ResourceCategories");
            navigate(routes.resources);
            setNotification({
              title: t("resource.copy.success.title"),
              description: t("resource.copy.success.description"),
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: t("resource.copy.failure.title"),
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeCopy();
            setSubmitting(false);
          }
        }}
      >
        <Input
          label={t("resource.copy.label")}
          name="copyName"
          id="copyName"
          type="text"
          required
        />
      </Modal>
    </AppLayout>
  );
}
