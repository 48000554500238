import React, { useEffect, useState } from "react";
import AppLayout from "components/AppLayout";
import ItemList from "components/ItemList";
import routes from "utils/routes";
import Modal from "components/Modal";
import {
  useCreateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoriesQuery,
  useRenameProductCategoryMutation,
} from "generated/graphql";
import useModal from "hooks/useModal";
import DeleteModal from "components/DeleteModal";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import { CreateCategorySchema } from "validation/createCategory.validation";
import { RenameCategorySchema } from "validation/renameCategory.validation";
import { usePagination } from "hooks/usePagination";
import { useNavigate } from "react-router-dom";
import { useGlobalNotification } from "hooks/useNotification";
import { useQueryClient } from "react-query";

const pageSize = 60;

export default function Products() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { page, skip, nextPage, prevPage } = usePagination(pageSize);
  const { status, data, error } = useGetProductCategoriesQuery({
    pageSize: pageSize,
    skip: skip,
  });

  const [, setNotification] = useGlobalNotification();

  useEffect(() => {
    if (error instanceof Error) {
      setNotification({
        title: "Couldn't load products",
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, setNotification]);

  const itemList =
    data?.productCategories.nodes.map((cat) => ({
      category: { name: cat.name, id: cat.id },
      listItems: cat.products.nodes.map((prod) => ({
        price: prod.sellPrice,
        name: prod.name,
        id: prod.id,
      })),
    })) ?? [];

  itemList.forEach(cat => {
    cat.listItems.sort((a, b) => a.name.localeCompare(b.name));
  });
  itemList.sort((a, b) => a.category.name.localeCompare(b.category.name));

  const [isCreateOpen, openCreate, closeCreate] = useModal();

  const onCreate = () => {
    if (status === "loading") {
      return;
    }
    if (data) {
      if (data.productCategories.nodes.length === 0) {
        openCreate();
      } else {
        navigate(routes.productCreate);
      }
    }
  };

  const Title = () => {
    return (
      <div className="md:flex md:items-center md:justify-between my-2">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {t("products.title")}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            onClick={openCreate}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("products.category.create.title")}
          </button>
          <button
            onClick={onCreate}
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("products.create")}
          </button>
        </div>
      </div>
    );
  };

  const { mutateAsync: create } = useCreateProductCategoryMutation();

  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const { mutateAsync: rename } = useRenameProductCategoryMutation();
  const [isRenameOpen, openRename, closeRename] = useModal();
  const onCategoryEdit = async (categoryId: number) => {
    setSelectedCategoryId(categoryId);
    openRename();
  };

  const { mutateAsync: deleteCategory } = useDeleteProductCategoryMutation();
  const [isDeleteOpen, openDelete, closeDelete] = useModal();
  const onCategoryDelete = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
    openDelete();
  };

  return (
    <AppLayout title={<Title />} currentNav="nav.products">
      <ItemList
        onItemClick={(product) => navigate(`${routes.products}/${product.id}`)}
        onCategoryEdit={onCategoryEdit}
        onCategoryDelete={onCategoryDelete}
        items={itemList}
        hasUnit={false}
      />
      <Modal
        title={t("products.category.title")}
        enterText={t("products.category.enter")}
        open={isCreateOpen}
        onClose={closeCreate}
        initialValues={{
          name: "",
        }}
        onSubmit={async (values) => {
          try {
            await create(values);
            queryClient.invalidateQueries("GetProductCategories");
            setNotification({
              title: "Category created",
              description: `Category "${values.name}" has been created`,
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: "Couldn't create category",
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeCreate();
          }
        }}
        validationSchema={CreateCategorySchema}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {t("products.category.create.description")}
          </p>
          <div className="mt-4">
            <Input
              label={t("products.category.create.name")}
              type="text"
              name="name"
              id="name"
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={t("products.category.rename.title")}
        enterText={t("products.category.rename.enter")}
        open={isRenameOpen}
        onClose={closeRename}
        validationSchema={RenameCategorySchema}
        initialValues={{
          newName: "",
        }}
        onSubmit={async (values) => {
          if (selectedCategoryId === undefined) {
            return;
          }
          const vars = {
            newName: values.newName,
            id: selectedCategoryId,
          };
          try {
            await rename(vars);
            await queryClient.invalidateQueries("GetProductCategories");
            setNotification({
              title: "Category renamed",
              description: `Category has been renamed`,
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: "Couldn't rename category",
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeRename();
          }
        }}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {t("products.category.rename.description")}
          </p>
          <div className="mt-4">
            <Input
              label={t("products.category.rename.name")}
              type="text"
              name="newName"
              id="newName"
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        title={t("products.category.delete.title")}
        description={t("products.category.delete.description")}
        enterText={t("products.category.delete.enter")}
        isOpen={isDeleteOpen}
        onClose={closeDelete}
        onEnter={async () => {
          if (selectedCategoryId === undefined) {
            console.error("category id not selected");
            closeDelete();
            return;
          }
          try {
            await deleteCategory({
              id: selectedCategoryId,
            });
            queryClient.invalidateQueries("GetProductCategories");
            setNotification({
              title: "Category deleted",
              description: `Category has been deleted`,
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: "Couldn't delete category",
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeDelete();
          }
        }}
      />
    </AppLayout>
  );
}
