import FieldInput from "components/FieldInput";
import FieldPriceInput from "components/FieldPriceInput";
import FieldSelect from "components/FieldSelect";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ResourceSchema } from "validation/resource.validation";
import { ResourceEditorProps } from "./types";
import { useGlobalNotification } from "hooks/useNotification";
import { useGetResourceCategoryNamesQuery } from "generated/graphql";

const pageSize = 9999;

export default function ResourceEditor(props: ResourceEditorProps) {
  const [t] = useTranslation();
  const [, setNotification] = useGlobalNotification();

  const { data: categoriesData, error: c_error } =
    useGetResourceCategoryNamesQuery({
      pageSize: pageSize,
      skip: 0,
    });

  useEffect(() => {
    if (c_error instanceof Error) {
      setNotification({
        title: "Couldn't load resource categories",
        description: c_error.message,
        iconType: "error",
      });
    }
  }, [c_error, setNotification]);

  const categories = categoriesData?.resourceCategories.nodes;

  const defaultUnits = ["g", "kg", "l", "ml", t("unit.piece")];

  const initialValues = {
    name: props.resource?.name ?? "",
    price: props.resource?.price ?? 0,
    categoryId: props.resource ? props.resource.categoryId.toString() : "",
    unit: props.resource?.unit ?? "",
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={props.onSave}
          validationSchema={ResourceSchema}
        >
          <Form>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t("resourceeditor.title")}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {t("resourceeditor.description")}
                  </p>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <FieldInput
                    label={t("resourceeditor.name")}
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="resourceName"
                  />
                  <FieldSelect
                    label={t("resourceeditor.unit")}
                    id="unit"
                    name="unit"
                  >
                    {defaultUnits.map((unit, index) => (
                      <option key={index} value={unit}>
                        {unit}
                      </option>
                    ))}
                  </FieldSelect>
                  <FieldPriceInput
                    label={t("resourceeditor.cost")}
                    type="number"
                    name="price"
                    id="price"
                    placeholder="0.00"
                    step={0.0001}
                  />
                  <FieldSelect
                    label={t("resourceeditor.category.title")}
                    id="categoryId"
                    name="categoryId"
                  >
                    <option value="">
                      {t("resourceeditor.category.label")}
                    </option>
                    {categories &&
                      categories.map((category, index) => (
                        <option key={index} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </FieldSelect>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={props.onCancel}
                >
                  {t("resourceeditor.cancel")}
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t("resourceeditor.save")}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
