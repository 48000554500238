import ErrorText from "components/ErrorText";
import { useField } from "formik";
import React from "react";
import { classNames } from "utils/classnames";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  name: string;
};

export default function Input(props: Props) {
  const { label, className, ...rest } = props;
  const [field, meta] = useField(rest);
  return (
    <div>
      <label
        htmlFor={props.id}
        className={classNames(
          meta.touched && meta.error ? "text-red-600" : "text-gray-700",
          "block text-left text-sm font-medium"
        )}
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          className={classNames(
            meta.touched && meta.error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
              : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500",
            "max-w-lg shadow-sm block w-full sm:text-sm rounded-md"
          )}
          {...field}
          {...rest}
        />
      </div>
      <ErrorText error={meta.touched && meta.error} />
    </div>
  );
}
