import React, { PropsWithChildren } from "react";
import { useField } from "formik";
import ErrorText from "components/ErrorText";
import { classNames } from "utils/classnames";

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label: string;
  name: string;
};

export default function FieldSelect({
  label,
  className,
  children,
  ...rest
}: PropsWithChildren<Props>) {
  const [field, meta] = useField(rest);

  const isError = meta.touched && meta.error;

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={rest.id}
        className={classNames(
          isError ? "text-red-600" : "text-gray-700",
          "block text-sm font-medium sm:mt-px sm:pt-2"
        )}
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0">
        <select
          className={classNames(
            isError
              ? "focus:ring-red-500 focus:border-red-500 border-red-300"
              : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300",
            "block w-full pl-3 pr-10 py-2 max-w-lg text-basefocus:outline-none sm:text-sm rounded-md"
          )}
          {...field}
          {...rest}
        >
          {children}
        </select>
      </div>
      <ErrorText error={isError} />
    </div>
  );
}
