import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useLang = () => {
  const [, i18] = useTranslation();
  const [lang, setLang] = useState("en");

  useEffect(() => {
    const _lang = localStorage.getItem("prefLang") || "en";
    setLang(_lang);
  }, []);

  const changeLang = (lang: string) => {
    i18.changeLanguage(lang);
    localStorage.setItem("prefLang", lang);
    setLang(lang);
  };

  return [lang, changeLang] as const;
};
