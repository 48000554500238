const routes = {
  index: "/",
  products: "/products",
  productCreate: "/products/create",
  resources: "/resources",
  resourceCreate: "/resources/create",
  configuration: "/configuration",
  login: "/auth/login",
  register: "/auth/register",
} as const;

export default routes;
