import { useWhoAmIQuery } from "generated/graphql";
import Cookies from "js-cookie";

export function useAuth() {
  const { status, error, isSuccess } = useWhoAmIQuery();

  if (!Cookies.get("jwt") || error) {
    return {
      loading: false,
      authenticated: false,
    };
  }

  if (isSuccess) {
    return {
      loading: false,
      authenticated: true,
    };
  }
  return {
    loading: status === "loading",
    authenticated: false,
  };
}
