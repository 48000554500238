import { useResourceCategoriesQuery } from "generated/graphql";
import { useGlobalNotification } from "hooks/useNotification";
import { usePagination } from "hooks/usePagination";
import { useEffect } from "react";

const resourcePageSize = 60;

export default function useGetResources() {
  const pagination = usePagination(resourcePageSize);
  const [, setNotification] = useGlobalNotification();
  const {
    data,
    status,
    error: r_error,
  } = useResourceCategoriesQuery({
    pageSize: resourcePageSize,
    skip: pagination.skip,
  });

  useEffect(() => {
    if (r_error && r_error instanceof Error) {
      setNotification({
        title: "Couldn't load product categories",
        description: r_error.message,
        iconType: "error",
      });
    }
  }, [r_error, setNotification]);

  return { ...pagination, data, status };
}
