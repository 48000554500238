import AppLayout from "components/AppLayout";
import ErrorText from "components/ErrorText";
import { Formik } from "formik";
import {
  useDefaultProfitMarginQuery,
  useSetProfitMarginMutation,
} from "generated/graphql";
import { useGlobalNotification } from "hooks/useNotification";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

export default function Configuration() {
  const [t] = useTranslation();
  const [, setNotification] = useGlobalNotification();
  const { data, error } = useDefaultProfitMarginQuery();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (error instanceof Error) {
      setNotification({
        title: "An error happened during loading profit margin",
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, setNotification]);

  const { mutateAsync: setProfit } = useSetProfitMarginMutation();

  return (
    <AppLayout title={t("config.title")} currentNav="nav.config">
      <div className="bg-white overflow-hidden shadow rounded-lg mt-8 max-w-lg">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-lg leading-6 font-medium text-gray-900">
            {t("config.profitmargin.title")}
          </h1>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {t("config.profitmargin.description")}
          </p>
          {data && (
            <Formik
              initialValues={{
                profitMargin:
                  data.whoAmI.organization.defaultProfitMargin * 100,
              }}
              onSubmit={async (values) => {
                try {
                  await setProfit({
                    profitMargin: values.profitMargin / 100,
                  });
                  queryClient.invalidateQueries("DefaultProfitMargin");
                  setNotification({
                    title: t("config.profitmargin.noti.saved.title"),
                    description: t(
                      "config.profitmargin.noti.saved.description"
                    ),
                    iconType: "success",
                  });
                } catch (error: any) {
                  setNotification({
                    title: "Couldn't updated profit margin",
                    description: error.message,
                    iconType: "error",
                  });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  className="mt-6 flex items-center gap-4"
                  onSubmit={handleSubmit}
                >
                  <div className="relative rounded-md shadow-sm">
                    <input
                      type="number"
                      name="profitMargin"
                      id="profitMargin"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      placeholder="0.00"
                      step="0.01"
                      min="0"
                      aria-describedby="price-currency"
                      value={values.profitMargin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        %
                      </span>
                    </div>
                    <ErrorText
                      error={touched.profitMargin && errors.profitMargin}
                    />
                  </div>

                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("config.profitmargin.set")}
                  </button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </AppLayout>
  );
}
