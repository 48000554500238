import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import i18next from "i18next";
import { initReactI18next, withTranslation } from "react-i18next";
import translation_en from "translations/en.json";
import translation_hu from "translations/hu.json";
import Providers from "Providers";
import routes from "utils/routes";
import Products from "pages/products";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import withProtected from "components/ProtectedRoute/hoc";
import ProductCreate from "pages/products/create";
import ProductView from "pages/products/[id]";
import Resources from "pages/resources";
import ResourceCreate from "pages/resources/create";
import ResourceEdit from "pages/resources/[id]";
import Configuration from "pages/configuration";
import EditProduct from "pages/products/[id]/edit";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: translation_en,
    },
    hu: {
      translation: translation_hu,
    },
  },
  lng: localStorage.getItem("prefLang") ?? undefined,
  fallbackLng: "en",
});

function App() {
  return (
    <Providers>
      <BrowserRouter>
        <Routes>
          <Route path={routes.index} element={withProtected(<Products />)} />
          <Route path={routes.products} element={withProtected(<Products />)} />
          <Route
            path={routes.productCreate}
            element={withProtected(<ProductCreate />)}
          />
          <Route
            path={`${routes.products}/:id`}
            element={withProtected(<ProductView />)}
          />
          <Route
            path={`${routes.products}/:id/edit`}
            element={withProtected(<EditProduct />)}
          />
          <Route
            path={routes.resources}
            element={withProtected(<Resources />)}
          />
          <Route
            path={routes.resourceCreate}
            element={withProtected(<ResourceCreate />)}
          />
          <Route
            path={`${routes.resources}/:id`}
            element={withProtected(<ResourceEdit />)}
          />
          <Route
            path={routes.configuration}
            element={withProtected(<Configuration />)}
          />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.register} element={<Register />} />
        </Routes>
      </BrowserRouter>
    </Providers>
  );
}

export default withTranslation()(App);
