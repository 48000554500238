import * as yup from "yup";

const requiredTxt = "This field is required";

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please provide a valid email")
    .required(requiredTxt),
  password: yup.string().required(requiredTxt),
});
