import { useGetProductCategoryNamesQuery } from "generated/graphql";
import { useGlobalNotification } from "hooks/useNotification";
import { useEffect } from "react";

const productCategoriesPageSize = 99999;

export default function useGetProductCategories() {
  const [, setNotification] = useGlobalNotification();

  const { data, status, error } = useGetProductCategoryNamesQuery({
    pageSize: productCategoriesPageSize,
    skip: 0,
  });

  useEffect(() => {
    if (error && error instanceof Error) {
      setNotification({
        title: "Couldn't load product categories",
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, setNotification]);

  return { data, status };
}
