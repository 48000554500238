import AppLayout from "components/AppLayout";
import DeleteModal from "components/DeleteModal";
import Input from "components/Input";
import ItemList from "components/ItemList";
import Modal from "components/Modal";
import Notification from "components/Notification";
import { ListItem } from "components/types";
import {
  useDeleteResourceCategoryMutation,
  useRenameResourceCategoryMutation,
  useResourceCategoriesQuery,
  useResourceCategoryCreateMutation,
} from "generated/graphql";
import useModal from "hooks/useModal";
import { useNotification } from "hooks/useNotification";
import { usePagination } from "hooks/usePagination";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import routes from "utils/routes";
import { CreateCategorySchema } from "validation/createCategory.validation";
import { RenameCategorySchema } from "validation/renameCategory.validation";

const imgUrl = "/EmptyProduct.png";

const pageSize = 60;

export default function Resources() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onResourceClick = (resource: ListItem) => {
    const url = `${routes.resources}/${resource.id}`;
    navigate(url);
  };

  const { page, skip, nextPage, prevPage } = usePagination(pageSize);
  const { data, status, error } = useResourceCategoriesQuery({
    pageSize,
    skip,
  });
  const { title, description, show, close, setNotification, icon } =
    useNotification();

  useEffect(() => {
    if (error) {
      setNotification({
        title: "Couldn't load resources",
        description: (error as any).message,
        iconType: "error",
      });
    }
  }, [error, setNotification]);

  const listItems = data
    ? data.resourceCategories.nodes.map((category) => ({
        category: { name: category.name, id: category.id },
        listItems: category.resources.nodes.map((resource) => ({
          ...resource,
          imgUrl,
        })),
      }))
    : [];
    listItems.forEach(cat => {
      cat.listItems.sort((a, b) => a.name.localeCompare(b.name));
    });
    listItems.sort((a, b) => a.category.name.localeCompare(b.category.name));

  const { mutateAsync: createCategory } = useResourceCategoryCreateMutation();

  const onResourceCreate = () => {
    if (status === "loading") {
      return;
    }

    if (data) {
      if (data.resourceCategories.nodes.length === 0) {
        openCreate();
      } else {
        navigate(routes.resourceCreate);
      }
    }
  };

  const Title = () => {
    return (
      <div className="md:flex md:items-center md:justify-between my-2">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {t("resources.title")}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            onClick={openCreate}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("resources.category.create.button")}
          </button>
          <button
            onClick={onResourceCreate}
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("resources.create")}
          </button>
        </div>
      </div>
    );
  };

  const [isOpen, openCreate, closeCreate] = useModal();

  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const { mutateAsync: rename } = useRenameResourceCategoryMutation();

  const [isRenameOpen, openRename, closeRename] = useModal();
  const onCategoryEdit = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
    openRename();
  };

  const { mutateAsync: deleteCategory } = useDeleteResourceCategoryMutation();
  const [isDeleteOpen, openDelete, closeDelete] = useModal();
  const onCategoryDelete = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
    openDelete();
  };

  return (
    <AppLayout title={<Title />} currentNav="nav.resources">
      <ItemList
        onItemClick={onResourceClick}
        onCategoryEdit={onCategoryEdit}
        onCategoryDelete={onCategoryDelete}
        items={listItems}
        hasUnit
      />
      <Modal
        title={t("resource.category.create.title")}
        enterText={t("resources.category.create.submit")}
        open={isOpen}
        onClose={closeCreate}
        initialValues={{
          name: "",
        }}
        validationSchema={CreateCategorySchema}
        onSubmit={async (values) => {
          try {
            await createCategory(values);
            queryClient.invalidateQueries("ResourceCategories");
            setNotification({
              title: "Category created",
              description: `Category "${values.name}" has been created`,
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: "Couldn't create category",
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeCreate();
          }
        }}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {t("resources.category.create.description")}
          </p>
          <div className="mt-4">
            <Input
              label={t("resources.category.create.name")}
              type="text"
              name="name"
              id="name"
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={t("resources.category.rename.title")}
        enterText={t("resources.category.rename.enter")}
        open={isRenameOpen}
        onClose={closeRename}
        initialValues={{
          newName: "",
        }}
        validationSchema={RenameCategorySchema}
        onSubmit={async (values) => {
          const vars = {
            ...values,
            id: selectedCategoryId as number,
          };
          try {
            await rename(vars);
            queryClient.invalidateQueries("ResourceCategories");
            setNotification({
              title: "Category renamed",
              description: `Category has been renamed`,
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: "Couldn't rename category",
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeRename();
          }
        }}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {t("resources.category.rename.description")}
          </p>
          <div className="mt-4">
            <Input
              label={t("resources.category.rename.name")}
              type="text"
              name="newName"
              id="newName"
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        title={t("resources.category.delete.title")}
        description={t("resources.category.delete.description")}
        enterText={t("resources.category.delete.enter")}
        isOpen={isDeleteOpen}
        onClose={closeDelete}
        onEnter={async () => {
          if (selectedCategoryId === null) {
            console.error("category id not selected");
            return;
          }
          try {
            await deleteCategory({
              id: selectedCategoryId as number,
            });
            queryClient.invalidateQueries("ResourceCategories");
            setNotification({
              title: "Category deleted",
              description: `Category has been deleted`,
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: "Couldn't delete category",
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeDelete();
          }
        }}
      />
      <Notification
        title={title}
        description={description}
        icon={icon}
        show={show}
        close={close}
      />
    </AppLayout>
  );
}
