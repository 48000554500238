import AppLayout from "components/AppLayout";
import ProductEditor from "components/ProductEditor";
import {
  CreateProductMutationVariables,
  useGetProductQuery,
  useUpdateProductMutation,
} from "generated/graphql";
import { useGlobalNotification } from "hooks/useNotification";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import routes from "utils/routes";

export default function EditProduct() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const _id = parseInt(id as string);
  const [, setNotification] = useGlobalNotification();
  const queryClient = useQueryClient();

  const { data, status, error } = useGetProductQuery({ id: _id });

  useEffect(() => {
    if (error instanceof Error) {
      setNotification({
        title: `Couldn't load product with ID: ${id}`,
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, id, setNotification]);

  const { mutateAsync: update } = useUpdateProductMutation();

  const onSave = async (product: CreateProductMutationVariables) => {
    try {
      const { categoryId, ...rest } = product;
      const _categoryId = parseInt(categoryId as unknown as string);
      const vars = {
        id: _id,
        categoryId: _categoryId,
        ...rest,
      };
      await update(vars);
      await queryClient.invalidateQueries("GetProduct");

      navigate(`${routes.products}/${id}`);
      setNotification({
        title: "Product updated",
        description: "Product has been successfully updated on our servers",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: "Couldn't update product",
        description: error.message,
        iconType: "error",
      });
    }
  };

  return (
    <AppLayout title={t("product.edit.title")} currentNav="nav.products">
      {data && (
        <ProductEditor
          product={data.product}
          onSave={onSave}
          cancelLink={`${routes.products}/${id}`}
        />
      )}
    </AppLayout>
  );
}
