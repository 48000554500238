import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import React, { useContext, useState } from "react";

type IconType = "success" | "error";

type StateType = {
  title: string;
  description: string;
  iconType: IconType;
};

export const useNotification = (initial?: StateType) => {
  const [show, setShow] = useState(false);
  const [{ title, description, iconType }, setNoti] = useState<StateType>(
    initial ?? {
      title: "",
      description: "",
      iconType: "error",
    }
  );
  const close = () => setShow(false);
  const open = () => setShow(true);

  const setNotification = (notification: StateType) => {
    setNoti(notification);
    open();
  };

  const icon = getNotiIcon(iconType);

  return {
    title,
    description,
    icon,
    show,
    close,
    open,
    setNotification,
  };
};

export const getNotiIcon = (type: IconType) => {
  return type === "success" ? (
    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  ) : (
    <ExclamationCircleIcon
      className="h-6 w-6 text-red-400"
      aria-hidden="true"
    />
  );
};

export type NotificationType = StateType & { show: boolean };

const initial: NotificationType = {
  title: "",
  description: "",
  iconType: "error",
  show: false,
};
const defaultValue: [NotificationType, (value: NotificationType) => void] = [
  initial,
  (_: NotificationType) => {},
];
export const NotificationContext =
  React.createContext<[NotificationType, (value: NotificationType) => void]>(
    defaultValue
  );

export const useGlobalNotification = () => {
  const [notification, setNoti] = useContext(NotificationContext);

  const setNotification = (newValue: StateType) => {
    setNoti({ ...newValue, show: true });
  };

  return [notification, setNotification] as [
    NotificationType,
    (value: StateType) => void
  ];
};
