import ErrorText from "components/ErrorText";
import { useField } from "formik";
import React from "react";
import { classNames } from "utils/classnames";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  name: string;
  currency?: string;
  currencySign?: string;
};

const defaultCurrency = "EUR";
const defaultCurrencySign = "€";

export default function FieldPriceInput({
  label,
  className,
  currency,
  currencySign,
  ...rest
}: Props) {
  const [field, meta] = useField(rest);

  const isError = meta.touched && meta.error;

  const _currency = currency ?? defaultCurrency;
  const _currencySign = currencySign ?? defaultCurrencySign;

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-3 sm:pt-5">
      <label
        htmlFor={rest.id}
        className={classNames(
          isError ? "text-red-600" : "text-gray-700",
          "block text-sm font-medium sm:mt-px sm:pt-2"
        )}
      >
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{_currencySign}</span>
        </div>
        <input
          className={classNames(
            isError
              ? "focus:ring-red-500 focus:border-red-500  border-red-300"
              : "focus:ring-indigo-500 focus:border-indigo-500  border-gray-300",
            currency !== "" ? "pr-12" : "",
            "block w-full  pl-7 sm:text-sm rounded-md"
          )}
          {...field}
          {...rest}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{_currency}</span>
        </div>
      </div>
      <ErrorText error={isError} />
    </div>
  );
}
