import { useDefaultProfitMarginQuery } from "generated/graphql";
import { useGlobalNotification } from "hooks/useNotification";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function useProfitMargin() {
  const { data, status, error } = useDefaultProfitMarginQuery();
  const [, setNotification] = useGlobalNotification();
  const [t] = useTranslation();

  useEffect(() => {
    if (error instanceof Error) {
      setNotification({
        title: t("producteditor.profitmargin.error"),
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, setNotification, t]);
  return { data, status };
}
