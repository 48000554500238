import FieldInput from "components/FieldInput";
import FieldPriceInput from "components/FieldPriceInput";
import FieldSelect from "components/FieldSelect";
import ResourcePicker from "components/ResourcePicker";
import { _Resource } from "components/ResourcePicker/types";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateProductSchema } from "validation/createProduct.validation";
import { ProductEditorProps } from "./types";
import useGetProductCategories from "./useGetProductCategories";
import useProfitMargin from "./useProfitMargin";
import calculateInitialProduct from "./calculateInitialProduct";
import deepCopy from "utils/deepCopy";
import { roundAccurately } from "utils/round";

export default function ProductEditor(props: ProductEditorProps) {
  const [t] = useTranslation();
  const { data: categories } = useGetProductCategories();
  const { data: orgProfitMargin, status: profitMarginStatus } =
    useProfitMargin();

  const defaultProfitMargin =
    orgProfitMargin?.whoAmI.organization.defaultProfitMargin ?? 0;
  const product = calculateInitialProduct(props.product, defaultProfitMargin);

  const [selectedResources, setSelectedResources] = useState(
    props.product ? deepCopy(props.product.resourceItems) : []
  );

  const resourcePrices = selectedResources
    .map((res) =>
      isNaN(res.amount * res.resource.price)
        ? 0
        : res.amount * res.resource.price
    )
    .reduce((prev, current) => prev + current, 0);

  const onSelect = (_resource: _Resource) => {
    setSelectedResources((oldResources) => {
      const index = oldResources
        .map((r) => r.resource.id)
        .indexOf(_resource.id);
      if (index === -1) {
        const newResourceItem = {
          amount: 1,
          resource: _resource,
        };
        return [...oldResources, newResourceItem];
      } else {
        const newResources = [...oldResources];
        newResources.splice(index, 1);
        return newResources;
      }
    });
  };

  const onSetAmount = (amount: number, resourceId: number) => {
    setSelectedResources((oldResources) => {
      const newResources = [...oldResources];
      const index = newResources.findIndex((r) => r.resource.id === resourceId);
      if (index === -1) {
        console.error("Forbidden way of setting resources");
      } else {
        newResources[index].amount = amount;
      }
      return newResources;
    });
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg mt-8">
      <div className="px-4 py-5 sm:p-6">
        <Formik
          enableReinitialize={
            !props.product || profitMarginStatus !== "success"
          }
          initialValues={product}
          onSubmit={(values) => {
            const newValues = {
              ...values,
              profitMargin: values.profitMargin / 100,
            };
            const newSelectedResources = selectedResources.map((res) => ({
              id: res.resource.id,
              //TODO: Handle this properly
              amount: parseFloat(res.amount as any),
            }));
            props.onSave({
              ...newValues,
              selectedResources: newSelectedResources,
            });
          }}
          validationSchema={CreateProductSchema}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <form
              className="space-y-8 divide-y divide-gray-200"
              onSubmit={handleSubmit}
            >
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t("producteditor.product.title")}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      {t("producteditor.product.description")}
                    </p>
                  </div>

                  <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <FieldInput
                      label={t("producteditor.product.name")}
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="productName"
                    />
                    <FieldSelect
                      label={t("producteditor.category")}
                      id="categoryId"
                      name="categoryId"
                    >
                      <option value="">
                        {t("resourceeditor.category.label")}
                      </option>
                      {categories &&
                        categories.productCategories.nodes.map(
                          (category, index) => (
                            <option key={index} value={category.id}>
                              {category.name}
                            </option>
                          )
                        )}
                    </FieldSelect>
                  </div>
                </div>

                <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t("producteditor.resource.title")}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      {t("producteditor.resource.description")}
                    </p>
                  </div>
                  <ResourcePicker
                    selectedResources={selectedResources.map((item) => ({
                      amount: item.amount,
                      ...item.resource,
                    }))}
                    select={onSelect}
                    setAmount={onSetAmount}
                  />
                </div>

                <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t("producteditor.price.title")}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      {t("producteditor.price.description")}
                    </p>
                  </div>
                  <FieldPriceInput
                    label={t("producteditor.utilities")}
                    type="number"
                    name="utilities"
                    id="utilities"
                    placeholder="0.00"
                    min={0}
                    step={0.0001}
                    aria-describedby="price-currency"
                  />
                  <FieldPriceInput
                    label={t("producteditor.profitmargin.label")}
                    type="number"
                    name="profitMargin"
                    id="profitMargin"
                    placeholder="0.00"
                    step="0.01"
                    min="0"
                    aria-describedby="price-currency"
                    currency=""
                    currencySign="%"
                  />

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-3 sm:pt-5">
                    <p className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t("producteditor.fabricationcost")}
                    </p>
                    <div className="mt-2 sm:col-span-2">
                      <p className="max-w-lg w-full sm:max-w-xs sm:text-sm text-gray-700">
                        {typeof values.utilities === "number"
                          ? roundAccurately(
                              resourcePrices + values.utilities,
                              4
                            )
                          : roundAccurately(resourcePrices, 4)}{" "}
                        €
                      </p>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-3 sm:pt-5">
                    <p className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t("producteditor.suggestedprice")}
                    </p>
                    <div className="mt-2 sm:col-span-2">
                      <p className="max-w-lg w-full sm:max-w-xs sm:text-sm text-gray-700">
                        {typeof values.utilities === "number"
                          ? roundAccurately(
                              (resourcePrices + values.utilities) *
                                (1 + values.profitMargin / 100),
                              4
                            )
                          : roundAccurately(
                              resourcePrices * (1 + values.profitMargin / 100),
                              4
                            )}{" "}
                        €
                      </p>
                    </div>
                  </div>

                  <FieldPriceInput
                    label={t("producteditor.sellprice")}
                    type="number"
                    name="sellPrice"
                    id="sellPrice"
                    placeholder="0.00"
                    min={0}
                    step={0.0001}
                  />
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <Link
                    to={props.cancelLink}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("producteditor.cancel")}
                  </Link>
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("producteditor.save")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
