import {
  getNotiIcon,
  NotificationContext,
  NotificationType,
} from "hooks/useNotification";
import React, { useState } from "react";
import Notification from "components/Notification";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export default function Providers({ children }: React.PropsWithChildren<{}>) {
  const notiState = useState<NotificationType>({
    title: "",
    description: "",
    iconType: "error",
    show: false,
  });
  const [notification, setNotification] = notiState;
  const close = () => setNotification({ ...notification, show: false });

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationContext.Provider value={notiState}>
        {children}
        <Notification
          title={notification.title}
          description={notification.description}
          icon={getNotiIcon(notification.iconType)}
          show={notification.show}
          close={close}
        />
      </NotificationContext.Provider>
    </QueryClientProvider>
  );
}
