import React, { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  CogIcon,
  MenuAlt2Icon,
  TagIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import routes from "utils/routes";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "utils/classnames";
import Cookies from "js-cookie";
import { useLang } from "hooks/useLang";

interface Props {
  title: string | JSX.Element;
  currentNav: "nav.products" | "nav.resources" | "nav.config";
}

function NavigationItem(props: {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  currentNav: string;
}) {
  return (
    <Link
      to={props.href}
      className={classNames(
        props.name === props.currentNav
          ? "bg-gray-100 text-gray-900"
          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
        "group flex propss-center px-2 py-2 text-base font-medium rounded-md"
      )}
    >
      <props.icon
        className={classNames(
          props.name === props.currentNav
            ? "text-gray-500"
            : "text-gray-400 group-hover:text-gray-500",
          "mr-4 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {props.name}
    </Link>
  );
}

export default function AppLayout(props: React.PropsWithChildren<Props>) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const onSignOut = () => {
    Cookies.remove("jwt");
    navigate(routes.login);
  };
  const userNavigation = [
    { name: t("usernav.profile"), onClick: () => {} },
    { name: t("usernav.settings"), onClick: () => {} },
    { name: t("usernav.signout"), onClick: onSignOut },
  ];

  const navigation = [
    { name: t("nav.products"), href: routes.products, icon: TagIcon },
    { name: t("nav.resources"), href: routes.resources, icon: ViewGridIcon },
    { name: t("nav.config"), href: routes.configuration, icon: CogIcon },
  ] as const;

  const [lang, changeLang] = useLang();

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="/Marginalize.png"
                  alt="Marginalize"
                />
              </div>
              <div className="mt-5 flex-1 flex flex-col justify-between h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  <NavigationItem
                    name={navigation[0].name}
                    href={navigation[0].href}
                    icon={navigation[0].icon}
                    currentNav={props.currentNav}
                  />
                  <NavigationItem
                    name={navigation[1].name}
                    href={navigation[1].href}
                    icon={navigation[1].icon}
                    currentNav={props.currentNav}
                  />
                  <NavigationItem
                    name={navigation[2].name}
                    href={navigation[2].href}
                    icon={navigation[2].icon}
                    currentNav={props.currentNav}
                  />
                </nav>
                <div className="pt-2 border-t border-gray-200">
                  <p className="text-center mt-2">
                    <span
                      onClick={() => changeLang("hu")}
                      className={classNames(
                        lang === "hu"
                          ? "font-bold text-black"
                          : "font-medium text-gray-600 cursor-pointer",
                        "ml-2"
                      )}
                    >
                      HU
                    </span>
                    <span className="text-gray-600 font-medium ml-2">/</span>
                    <span
                      onClick={() => changeLang("en")}
                      className={classNames(
                        lang === "en"
                          ? "font-bold text-black"
                          : "font-medium text-gray-600 cursor-pointer",
                        "ml-2"
                      )}
                    >
                      EN
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-8 w-auto"
                src="/Marginalize.png"
                alt="Marginalize"
              />
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="flex-1 px-2 bg-white space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.name === props.currentNav
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === props.currentNav
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
              <div className="pt-2 border-t border-gray-200">
                <p className="text-center mt-2">
                  <span
                    onClick={() => changeLang("hu")}
                    className={classNames(
                      lang === "hu"
                        ? "font-bold text-black"
                        : "font-medium text-gray-600 cursor-pointer",
                      "ml-2"
                    )}
                  >
                    HU
                  </span>
                  <span className="text-gray-600 font-medium ml-2">/</span>
                  <span
                    onClick={() => changeLang("en")}
                    className={classNames(
                      lang === "en"
                        ? "font-bold text-black"
                        : "font-medium text-gray-600 cursor-pointer",
                      "ml-2"
                    )}
                  >
                    EN
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              <form className="w-full flex md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  {t("searchbar.placeholder")}
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder={t("search")}
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl py-4 sm:truncate">
                    {props.title}
                  </h2>
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
