import { useAuth } from "hooks/useAuth";
import React, { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import routes from "utils/routes";

export default function ProtectedRoute({ children }: PropsWithChildren<{}>) {
  const { loading, authenticated } = useAuth();
  const location = useLocation();

  if (!loading && !authenticated) {
    return <Navigate to={routes.login} state={{ from: location }} replace />;
  } else {
    return <>{children}</>;
  }
}
