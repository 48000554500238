import React from "react";
import AppLayout from "components/AppLayout";
import ResourceEditor from "components/ResourceEditor";
import { ResourceCreate as ResourceCreateType } from "components/ResourceEditor/types";
import routes from "utils/routes";
import { useCreateResourceMutation } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { useGlobalNotification } from "hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

export default function ResourceCreate() {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [, setNotification] = useGlobalNotification();
  const { mutateAsync: create } = useCreateResourceMutation();

  const onCreate = async (values: ResourceCreateType) => {
    try {
      const { categoryId, ...rest } = values;
      const cId = parseInt(categoryId);
      const variables = { categoryId: cId, ...rest };
      await create(variables);
      queryClient.invalidateQueries("GetResourceCategoryNames");
      navigate(routes.resources);
      setNotification({
        title: "Resource created",
        description: "Resource has been successfully created on our servers",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: "Couldn't create resource",
        description: error.message,
        iconType: "error",
      });
    }
  };

  return (
    <AppLayout title={t("resource.create")} currentNav="nav.resources">
      <ResourceEditor
        onSave={onCreate}
        onCancel={() => navigate(routes.resources)}
      />
    </AppLayout>
  );
}
