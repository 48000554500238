import * as yup from "yup";

const requiredTxt = "This field is required";

export const RegisterSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please provide a valid email")
    .required(requiredTxt),
  firstName: yup.string().required(requiredTxt),
  lastName: yup.string().required(requiredTxt),
  password: yup.string().min(8).required(requiredTxt),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required(requiredTxt),
  organizationName: yup.string().required(requiredTxt),
});
