import { GetProductQuery } from "generated/graphql";

type Stats<T, S> = {
  netCosts: T;
  utilities: T;
  profitMargin: T;
  optimalSellPrice: T;
  actualSellPrice: T;
  actualToOptimal: T;
  actualProfitMargin: T;
  actualOptimalChange: S;
};

type StatsReal = Stats<number, "increase" | "decrease">;

type StatsPlaceholder = Stats<undefined, "loading...">;

export default function calculateProductStatus(
  product?: GetProductQuery["product"]
): StatsReal | StatsPlaceholder {
  if (!product) {
    return {
      netCosts: undefined,
      utilities: undefined,
      profitMargin: undefined,
      optimalSellPrice: undefined,
      actualSellPrice: undefined,
      actualProfitMargin: undefined,
      actualToOptimal: undefined,
      actualOptimalChange: "loading...",
    };
  }

  const netCosts = product.resourceItems
    .map((r) => r.amount * r.resource.price)
    .reduce((prev, current) => prev + current, 0);

  const utilities = product.utilities;
  const profitMargin = product.profitMargin;

  const totalCosts = netCosts + utilities;

  const optimalSellPrice = totalCosts * (1 + profitMargin);
  const actualSellPrice = product.sellPrice;

  const actualProfitMargin =
    totalCosts !== 0 ? actualSellPrice / totalCosts - 1 : Infinity;

  const actualToOptimal = actualSellPrice / optimalSellPrice - 1;

  const actualOptimalChange =
    actualSellPrice >= optimalSellPrice ? "increase" : "decrease";
  return {
    netCosts,
    utilities,
    profitMargin,
    optimalSellPrice,
    actualSellPrice,
    actualProfitMargin,
    actualToOptimal,
    actualOptimalChange,
  };
}
