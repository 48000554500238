import { useState } from "react";

export function usePagination(pageSize: number): {
  page: number;
  skip: number;
  nextPage: () => void;
  prevPage: () => void;
} {
  const [page, setPage] = useState(0);
  const skip = page * pageSize;
  const nextPage = () => setPage(page + 1);
  const prevPage = () => page > 1 && setPage(page - 1);
  return { page, skip, nextPage, prevPage };
}
