import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import React from "react";
import routes from "utils/routes";
import { LoginSchema } from "validation/login.validation";
import { useLoginMutation } from "generated/graphql";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import { useGlobalNotification } from "hooks/useNotification";
import { useLang } from "hooks/useLang";
import { classNames } from "utils/classnames";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

export default function Login() {
  const [t] = useTranslation();
  const { mutateAsync: login } = useLoginMutation();
  const navigate = useNavigate();
  const [, setNotification] = useGlobalNotification();
  const [lang, changeLang] = useLang();
  const queryClient = useQueryClient();

  const onLogin = async (email: string, password: string) => {
    try {
      const data = await login({
        email,
        password,
      });

      Cookies.set("jwt", data.login.access_token, {
        path: "/",
        expires: 5,
      });
      await queryClient.invalidateQueries("WhoAmI");
      await queryClient.refetchQueries("WhoAmI");

      navigate(routes.index);
    } catch (error: any) {
      setNotification({
        title: "Oops!",
        description: error.message,
        iconType: "error",
      });
    }
  };

  return (
    <div className="relative">
      <p className="absolute top-0 right-0 m-4">
        <span
          onClick={() => changeLang("hu")}
          className={classNames(
            lang === "hu" ? "font-bold" : "font-medium cursor-pointer",
            "text-gray-600 ml-2"
          )}
        >
          HU
        </span>
        <span className="text-gray-600 font-medium ml-2">/</span>
        <span
          onClick={() => changeLang("en")}
          className={classNames(
            lang === "en" ? "font-bold" : "font-medium cursor-pointer",
            "text-gray-600 ml-2"
          )}
        >
          EN
        </span>
      </p>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/Marginalize.png"
            alt="Marginalize"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t("login.title")}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {t("login.question")}{" "}
            <Link
              to={routes.register}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {t("login.toregister")}
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => onLogin(values.email, values.password)}
            >
              <Form className="space-y-6">
                <Input
                  label={t("login.email")}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <Input
                  label={t("login.password")}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {t("login.forgot")}
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("login.signin")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
