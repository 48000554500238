import * as yup from "yup";

const SelectedResourceSchema = yup.object().shape({
  id: yup.number().integer().positive().required(),
  amount: yup.number().required(),
});

export const CreateProductSchema = yup.object().shape({
  profitMargin: yup.number().min(0).required(),
  id: yup.number(),
  name: yup.string().required(),
  categoryId: yup.number().required(),
  sellPrice: yup.number().min(0).required(),
  selectedResources: yup.array().of(SelectedResourceSchema).required(),
  utilities: yup.number().min(0).required(),
});
