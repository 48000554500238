import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { ListItem } from "components/types";
import { useTranslation } from "react-i18next";

interface Props {
  items: {
    category: {
      name: string;
      id: number;
    };
    listItems: ListItem[];
  }[];
  hasUnit: boolean,
  onItemClick: (product: ListItem) => void;
  onCategoryEdit: (categoryId: number) => void;
  onCategoryDelete: (categoryId: number) => void;
}

export default function ItemList(props: Props) {
  const [t] = useTranslation();
  return (
    <div className="mt-4">
      {props.items.map((item, index) => (
        <div key={index} className="">
          <div className="flex items-baseline mb-4 mt-2 group">
            <h1 className="text-2xl font-semibold">{item.category.name}</h1>
            <button
              type="button"
              className="ml-8 mt-1 p-1 group-hover:opacity-100 opacity-0 inline-flex items-center text-sm leading-4 font-medium rounded-md text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={() => props.onCategoryEdit(item.category.id)}
            >
              <PencilIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              {t("itemlist.edit")}
            </button>
            <button
              type="button"
              className="ml-8 mt-1 p-1 group-hover:opacity-100 opacity-0 inline-flex items-center text-sm leading-4 font-medium rounded-md text-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={() => props.onCategoryDelete(item.category.id)}
            >
              <TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              {t("itemlist.delete")}
            </button>
          </div>
          {item.listItems.length !== 0 && (
            <div className="mb-8 flow-root bg-white px-2 sm:px-4 lg:px-6 rounded-md">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          {t("itemlist.name")}
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {t("itemlist.price")}
                        </th>
                        {props.hasUnit && (
                          <th scope="col" className="relative py-3.5 pl-3 pr-4  text-left text-sm font-semibold text-gray-900 sm:pr-0">
                            {t("itemlist.unit")}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {item.listItems.map((item) => (
                        <tr key={item.id} className="hover:bg-gray-50 hover:cursor-pointer" onClick={() => props.onItemClick(item)}>
                          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-2">
                            {item.name}
                          </td>
                          <td className="whitespace-nowrap px-2 py-3 text-sm text-gray-500">{item.price} €</td>
                          {props.hasUnit && (
                            <td className="relative whitespace-nowrap py-3 pl-3 pr-4 text-sm text-gray-500 sm:pr-2">{item.unit}</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
