import { Menu, Transition } from "@headlessui/react";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import AppLayout from "components/AppLayout";
import DeleteModal from "components/DeleteModal";
import Input from "components/Input";
import Modal from "components/Modal";
import {
  useCopyProductMutation,
  useDeleteProductMutation,
  useGetProductQuery,
} from "generated/graphql";
import useModal from "hooks/useModal";
import { useGlobalNotification } from "hooks/useNotification";
import calculateProductStatus from "hooks/useProductStats";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { classNames } from "utils/classnames";
import { roundAccurately } from "utils/round";
import routes from "utils/routes";

const showPercentage = (percentage?: number) => {
  if (percentage === undefined) {
    return "loading...";
  }
  return roundAccurately(percentage * 100, 2);
};

const showValue = (value?: number) => {
  if (value === undefined) {
    return "loading";
  }
  return roundAccurately(value, 4);
};

const Title = (props: { id: string; title: string }) => {
  const [t] = useTranslation();
  return (
    <div className="md:flex md:items-center md:justify-between my-2">
      <div className="flex-1 flex items-center min-w-0">
        <h2 className="text-2xl font-bold leading-7 mr-2 text-gray-900 sm:text-3xl sm:truncate">
          {props.title}
        </h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <Link
          to={`${routes.products}/${props.id}/edit`}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t("product.toedit")}
        </Link>
      </div>
    </div>
  );
};

export default function ProductView() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const _id = parseInt(id as string);
  const queryClient = useQueryClient();

  const { mutateAsync: copy } = useCopyProductMutation();
  const [isCopyOpen, openCopy, closeCopy] = useModal();

  const productActions = [
    {
      name: t("product.copy.title"),
      onClick: openCopy,
    },
  ];

  const { data, status, error } = useGetProductQuery({ id: _id });

  const [, setNotification] = useGlobalNotification();

  useEffect(() => {
    if (error instanceof Error) {
      setNotification({
        title: `Couldn't load product with ID: ${id}`,
        description: error.message,
        iconType: "error",
      });
    }
  }, [error, id, setNotification]);

  const { mutateAsync: deleteProduct } = useDeleteProductMutation();
  const [isOpenDelete, openDelete, closeDelete] = useModal();
  const onProductDelete = async () => {
    try {
      await deleteProduct({
        id: _id,
      });
      queryClient.invalidateQueries("GetProduct");
      queryClient.invalidateQueries("GetProductCategories");
      navigate(routes.products);
      setNotification({
        title: "Product deleted",
        description: "The product has been deleted from our servers",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: `Couldn't delete product with ID: ${id}`,
        description: error.message,
        iconType: "error",
      });
    }
  };

  const {
    netCosts,
    utilities,
    profitMargin,
    optimalSellPrice,
    actualSellPrice,
    actualProfitMargin,
    actualToOptimal,
    actualOptimalChange,
  } = calculateProductStatus(data ? data.product : undefined);

  return (
    <AppLayout
      title={
        <Title
          id={id as string}
          title={
            data
              ? `${data.product.category.name} / ${data.product.name}`
              : t("loading")
          }
        />
      }
      currentNav="nav.products"
    >
      <Menu as="div" className="inline-block relative text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            {t("product.actions")}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {productActions.map((action, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      onClick={action.onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 w-full text-sm"
                      )}
                    >
                      {action.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={openDelete}
                    className={classNames(
                      active ? "bg-red-100 text-red-900" : "text-red-700",
                      "block px-4 py-2 w-full text-sm"
                    )}
                  >
                    {t("product.delete.button")}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {/* Resource table list */}
      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("product.resource.name")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("product.resource.amount")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("product.resource.unitprice")}
                    </th>
                    <th scope="col" className="px-6 py-3 flex justify-end">
                      <p className="sr-only">Actions</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.product.resourceItems.map(
                      (resourceItem, resourceIdx) => (
                        <tr
                          key={resourceIdx}
                          className={
                            resourceIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {resourceItem.resource.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {roundAccurately(resourceItem.amount, 4)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {roundAccurately(resourceItem.resource.price, 4)}{" "}
                            EUR/
                            {resourceItem.resource.unit}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link
                              to={`${routes.resources}/${resourceItem.resource.id}`}
                              state={location.pathname}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              {t("product.resource.show")}
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/*Costs*/}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("product.cost.title")}
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.cost.resource")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {showValue(netCosts)} EUR
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.cost.utilities")}
              </dt>
              <div className="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                <dd className="text-sm text-gray-900">
                  {showValue(utilities)} EUR
                </dd>
              </div>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.cost.total")}
              </dt>
              <div className="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                <dd className="text-sm text-gray-900 font-semibold">
                  {showValue(
                    utilities && netCosts ? utilities + netCosts : undefined
                  )}{" "}
                  EUR
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>

      {/* Sales */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("product.sales.title")}
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.sales.profit")}
              </dt>
              <div className="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                <dd className="text-sm text-gray-900">
                  {showPercentage(profitMargin)} %
                </dd>
              </div>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.sales.optimal")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {showValue(optimalSellPrice)} EUR
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.sales.actual")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <p className="flex items-baseline text-sm font-semibold">
                  {showValue(actualSellPrice)} EUR
                  {actualOptimalChange === "increase" ? (
                    <ArrowSmUpIcon
                      className="self-center ml-4 flex-shrink-0 h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowSmDownIcon
                      className="self-center ml-4 flex-shrink-0 h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  <span
                    className={
                      actualOptimalChange === "increase"
                        ? "text-green-600"
                        : "text-red-600"
                    }
                  >
                    {showPercentage(actualToOptimal)} %
                  </span>
                </p>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {t("product.sales.actualprofit")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <p className="flex items-baseline text-sm font-semibold">
                  <span
                    className={
                      actualProfitMargin && actualProfitMargin >= 0
                        ? "text-green-600"
                        : "text-red-600"
                    }
                  >
                    {showPercentage(actualProfitMargin)} %
                  </span>
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <DeleteModal
        title={t("product.delete.title")}
        description={t("product.delete.description")}
        enterText={t("product.delete.button")}
        isOpen={isOpenDelete}
        onClose={closeDelete}
        onEnter={onProductDelete}
      />
      <Modal
        title={t("product.copy.title")}
        open={isCopyOpen}
        onClose={closeCopy}
        enterText={t("product.copy.button")}
        initialValues={{
          copyName: `${data ? data.product.name : "Product"} ${t("copy")}`,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await copy({
              copyName: values.copyName,
              id: _id,
            });
            queryClient.invalidateQueries("GetProductCategories");
            navigate(routes.products);
            setNotification({
              title: t("product.copy.success.title"),
              description: t("product.copy.success.description"),
              iconType: "success",
            });
          } catch (error: any) {
            setNotification({
              title: t("product.copy.failure.title"),
              description: error.message,
              iconType: "error",
            });
          } finally {
            closeCopy();
            setSubmitting(false);
          }
        }}
      >
        <Input
          label={t("product.copy.label")}
          name="copyName"
          id="copyName"
          type="text"
          required
        />
      </Modal>
    </AppLayout>
  );
}
