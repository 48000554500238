import {
  GetProductQuery,
  UpdateProductMutationVariables,
} from "generated/graphql";

const defaultProduct: UpdateProductMutationVariables = {
  id: -1,
  name: "",
  categoryId: undefined as any,
  sellPrice: 0,
  selectedResources: [],
  utilities: 0,
  profitMargin: 0,
};

export default function calculateInitialProduct(
  product?: GetProductQuery["product"],
  orgProductMargin?: number
): UpdateProductMutationVariables {
  if (product) {
    const { resourceItems, ...requiredFields } = product;
    const newProduct = { ...requiredFields };
    const update = {
      profitMargin: newProduct.profitMargin * 100,
      categoryId: newProduct.category.id,
      selectedResources: [],
    };
    return Object.assign(newProduct, update);
  } else {
    const validOrgProductMargin =
      orgProductMargin ?? defaultProduct.profitMargin;
    defaultProduct.profitMargin = validOrgProductMargin * 100;
    return defaultProduct;
  }
}
