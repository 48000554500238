import useGetResources from "components/ProductEditor/useGetResources";
import ResourceEditor from "components/ResourceEditor";
import SimpleModal from "components/SimpleModal";
import { useCreateResourceMutation } from "generated/graphql";
import useModal from "hooks/useModal";
import { useGlobalNotification } from "hooks/useNotification";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { classNames } from "utils/classnames";
import { roundAccurately } from "utils/round";
import { SelectedResource, _Resource } from "./types";

type Props = {
  selectedResources: SelectedResource[];
  select: (resource: _Resource) => void;
  setAmount: (amount: number, resourceId: number) => void;
};

export default function ResourcePicker(props: Props) {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const [, setNotification] = useGlobalNotification();

  const [searchText, setSearchText] = useState("");
  const { data: resources, status: resourcesStatus } = useGetResources();

  const isSelected = (item: _Resource) => {
    return props.selectedResources.some((_item) => _item.id === item.id);
  };

  const [isOpen, open, close] = useModal();
  const { mutateAsync: create } = useCreateResourceMutation();

  const onCreate = async (values: any) => {
    try {
      const { categoryId, ...rest } = values;
      const cId = parseInt(categoryId);
      const variables = { categoryId: cId, ...rest };
      await create(variables);
      await queryClient.invalidateQueries("ResourceCategories");
      setNotification({
        title: "Resource created",
        description: "Resource has been successfully created on our servers",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: "Couldn't create resource",
        description: error.message,
        iconType: "error",
      });
    } finally {
      close();
    }
  };

  return (
    <div className="xl:grid xl:grid-cols-2 xl:gap-8">
      <div>
        <h1 className="pt-4 text-sm font-medium text-gray-700">
          {t("resourcepicker.selected.title")}
        </h1>
        <div className="flex flex-col mt-4">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("resourcepicker.selected.name")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("resourcepicker.selected.amount")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("resourcepicker.selected.unitprice")}
                      </th>
                      <th scope="col" className="px-6 py-3 flex justify-end">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedResources.map((resource, resourceIdx) => (
                      <tr
                        key={resource.id}
                        className={
                          resourceIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {resource.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <input
                            className="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 max-w-lg shadow-sm block w-full sm:text-sm rounded-md"
                            type="number"
                            min="0"
                            step="0.0001"
                            value={resource.amount}
                            onChange={(ev) => {
                              //TODO: handle this properly (valueAsNumber doesn't work, because '0.' cannot be converted to number)
                              props.setAmount(
                                ev.target.value as any,
                                resource.id
                              );
                            }}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {roundAccurately(resource.price, 4)} € / {resource.unit}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => props.select(resource)}
                            type="button"
                            className="text-red-600 hover:text-red-900"
                          >
                            {t("resourcepicker.selected.delete")}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <h1 className="pt-4 text-sm font-medium text-gray-700 mb-4 border-t border-gray-200 lg:border-none">
            {t("resourcepicker.pick.title")}
          </h1>
          <button
            className="inline-flex items-center px-3 py-1.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={open}
            type="button"
          >
            {t("resourcepicker.pick.add")}
          </button>
        </div>

        <div>
          <div className="mt-1 relative flex items-center">
            <input
              type="text"
              name="search"
              id="search"
              placeholder={t("resourcepicker.search")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              value={searchText}
              onChange={(ev) => setSearchText(ev.currentTarget.value)}
            />
          </div>
        </div>

        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      {t("resourcepicker.selected.name")}
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-left text-sm font-semibold text-gray-900">
                      {t("resourcepicker.selected.unitprice")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {resourcesStatus === "success" 
                  && resources
                  && resources?.resourceCategories.nodes.map((category) => (
                    <Fragment key={category.id}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          {category.name}
                        </th>
                      </tr>
                      {category.resources.nodes
                      .filter(res => res.name.toLowerCase().includes(searchText.toLowerCase()))
                      .map((res, resIdx) => (
                        <tr
                          key={res.id}
                          className={classNames(resIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t', isSelected(res) && 'bg-indigo-50')}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            <input
                              name="isSelected"
                              type="checkbox"
                              className="h-4 w-4 mr-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              checked={isSelected(res)}
                              onChange={() => props.select(res)}
                            />
                            <span>{res.name}</span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm text-gray-500 sm:pr-3">
                          {roundAccurately(res.price, 4)} € / {res.unit}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      </div>
      <SimpleModal open={isOpen} onClose={close}>
        <ResourceEditor onSave={onCreate} onCancel={close} />
      </SimpleModal>
    </div>
  );
}
