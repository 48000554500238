import Cookies from "js-cookie";

export const authHeader = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Cookies.get("jwt")}`,
  },
});

export function getUrl() {
  if (
    process.env.NODE_ENV === "production" &&
    !process.env.REACT_APP_SCHEMA_PATH
  ) {
    throw new Error(
      "SCHEMA_PATH environment variable must be defined in production!"
    );
  }
  return process.env.REACT_APP_SCHEMA_PATH ?? "http://localhost:8000/graphql";
}
