import { useCallback, useState } from "react";

export default function useModal(): [
  isOpen: boolean,
  open: () => void,
  close: () => void
] {
  const [isOpen, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const open = useCallback(() => setOpen(true), [setOpen]);
  return [isOpen, open, close];
}
