import AppLayout from "components/AppLayout";
import ProductEditor from "components/ProductEditor";
import {
  CreateProductMutationVariables,
  useCreateProductMutation,
} from "generated/graphql";
import { useGlobalNotification } from "hooks/useNotification";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import routes from "utils/routes";

export default function ProductCreate() {
  const [t] = useTranslation();
  const [, setNotification] = useGlobalNotification();
  const queryClient = useQueryClient();

  const { mutateAsync: create } = useCreateProductMutation();

  const navigate = useNavigate();

  const onSave = async (product: CreateProductMutationVariables) => {
    try {
      const { categoryId, id, imageUrl, ...rest } =
        product as CreateProductMutationVariables & {
          id: number;
          imageUrl: string;
        };
      const _categoryId = parseInt(categoryId as unknown as string);
      const vars = {
        categoryId: _categoryId,
        ...rest,
      };

      const data = await create(vars);
      await queryClient.invalidateQueries("GetProductCategories");
      navigate(`${routes.products}/${data.createProduct.id}`);
      setNotification({
        title: "Product saved",
        description: "The product has been successfully added on the server",
        iconType: "success",
      });
    } catch (error: any) {
      setNotification({
        title: "Couldn't create the product",
        description: error.message,
        iconType: "error",
      });
    }
  };

  return (
    <AppLayout title={t("product.create.title")} currentNav="nav.products">
      <ProductEditor onSave={onSave} cancelLink={routes.products} />
    </AppLayout>
  );
}
