import Input from "components/Input";
import { Form, Formik } from "formik";
import { useRegisterMutation } from "generated/graphql";
import { useLang } from "hooks/useLang";
import { useGlobalNotification } from "hooks/useNotification";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "utils/classnames";
import routes from "utils/routes";
import { RegisterSchema } from "validation/register.validation";

export default function Register() {
  const [t] = useTranslation();
  const { mutateAsync: register } = useRegisterMutation();
  const navigate = useNavigate();
  const [_, setNotification] = useGlobalNotification();
  const [lang, changeLang] = useLang();

  const onRegister = async (values: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    repeatPassword: string;
    organizationName: string;
  }) => {
    const { repeatPassword, ...sendable } = values;
    try {
      const data = await register(sendable);
      if (data) {
        await navigate(routes.login);
        setNotification({
          title: "Registration successful",
          description: "Now you can safely log into your account",
          iconType: "success",
        });
      }
    } catch (error: any) {
      setNotification({
        title: "An error happened during registration",
        description: error.message,
        iconType: "error",
      });
    }
  };

  return (
    <div className="relative">
      <p className="absolute top-0 right-0 m-4">
        <span
          onClick={() => changeLang("hu")}
          className={classNames(
            lang === "hu"
              ? "font-bold text-black"
              : "font-medium text-gray-600 cursor-pointer",
            "ml-2"
          )}
        >
          HU
        </span>
        <span className="text-gray-600 font-medium ml-2">/</span>
        <span
          onClick={() => changeLang("en")}
          className={classNames(
            lang === "en"
              ? "font-bold text-black"
              : "font-medium text-gray-600 cursor-pointer",
            "ml-2"
          )}
        >
          EN
        </span>
      </p>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/Marginalize.png"
            alt="Marginalize"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t("register.title")}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {t("register.question")}{" "}
            <Link
              to={routes.login}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {t("register.tologin")}
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                email: "",
                firstName: "",
                lastName: "",
                password: "",
                repeatPassword: "",
                organizationName: "",
              }}
              validationSchema={RegisterSchema}
              onSubmit={onRegister}
            >
              <Form className="space-y-6">
                <Input
                  label={t("register.email")}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                />
                <Input
                  label={t("register.organization")}
                  id="organizationName"
                  name="organizationName"
                  type="text"
                  required
                />
                <Input
                  label={t("register.firstname")}
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  required
                />
                <Input
                  label={t("register.lastname")}
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  required
                />
                <Input
                  label={t("register.password")}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                />
                <Input
                  label={t("register.repeatpassword")}
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                />
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("register.signin")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
